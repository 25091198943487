@import "./Global.scss";

.appLayout {
  position: relative;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
  min-height: 100vh;
  width: 100%;

  background-color: #FCFCFC;
  background-image: url("../Images/Background.jpg");
  background-position: center;
  background-size: cover;
  
  
  // we dont use the overlay on this project
  // .bg-overlay {
  //   position: absolute;
  //   top: 0;left: 0;right: 0;bottom: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: rgb(252 252 252 / 80%);
  //   z-index: 1;
  // }

  > *:not(.bg-overlay) {
    z-index: 10;
  }
  
  .Header {
    display: grid;
    place-items: center;

    height: 100px;
    width: 100px;
    
    background-color: $primary-color;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.12);
    border-radius: 0 0 25px 0;

    cursor: pointer;

    &:hover {
      transition: all .2s;
      background-color: $secondary-color;
    }
    
    img {
      height: 50px;
    }
  }

  .App {
    display: flex;
    justify-content: center;
    align-items: center;
    
    .mobile-actions {
      display: none;
    }
    
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      min-height: calc(100vh - 80px);

      .app-page-container, .app-page-wrap-container {
        min-height: unset;
      }
      
      .app-page-btn {
        display: none;
      }

      .mobile-actions {
        display: flex;
        
        .app-page-btn {
          display: grid;
        }
      }
    }




    //Containers
    .ilustration {
      border-radius: 15px 95px 15px 15px;
      width: min(100%, 350px);
      max-height: 298px;
      object-fit: cover;

      @media screen and (max-width: 700px) {
        border-radius: 25px !important;
      }
    }

    .app-page-wrap-container {
      display: flex;
      justify-content: center;
      align-items: center;
      
      margin-bottom: 50px;
      width: min(515px, 85%);
      min-height: calc(100vh - 80px);
      
      @media screen and (max-width: 1000px) {
        margin-top: 50px;
        margin-bottom: 0;
      }
    }

    .app-page-container {
      display: flex;
      flex-direction: column;
      padding: 40px 50px;
      background-color: #343331;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
      border-radius: 25px;

      .appPageLogo {
        margin: 0 auto;
        height: 80px;
        margin: 20px 0;

        @media screen and (max-width: 1000px) {
          height: unset;
          width: min(100%, 300px);
        }
      }
    }

    .app-page-btn {
      display: grid;
      place-items: center;
      
      margin: 24px;
      width: 56px;
      height: 56px;
      
      border-radius: 50%;
      background-color: #282828;

      .sbi {
        font-size: 14px;
        font-weight: 700;
        color: #0F70D4;
      }
    }

    .radio_group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 8px;

      width: 100%;
      padding: 16px 26px;

      @media screen and (max-width: 1000px) {
        padding: 16px 0;
      }
    }

    .radio_field {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      
      width: 100%;
      padding: 10px 16px;

      border-radius: 8px;
      border: 1px solid #f2f4f8;
      
      cursor: pointer;

      label {
        font-size: clamp(15px,1.5vw, 17px);
        color: #e5e5e5;
      }

      .p-radiobutton-box {
        background: transparent !important;
      }
    }




  }
}


// Checkbox Primereact component
.Checkbox {
  display: flex;
  align-items: center;
  gap: 5px;

  label {
    font-size: clamp(14px, 2vw, 18px);
    font-weight: 500;
    
    color: #c4c4c4;
    line-height: clamp(1.1rem, 3vw, 1.5rem);

    cursor: pointer;
  }
}


// Botones que hago con iconos de primeicons dentro
.PiButton {
  margin: 25px 0 50px;
  padding: 20px;
  border-radius: 50%;
  border: none;
  outline: none;

  background-color: $primary-color;
  color: white;

  cursor: pointer;
  transition: all .3s;

  &:hover {
    transition: all .3s;
    transform: scale(1.1);
  }

  &.PiButtonDisabled {
    opacity: .2;
    cursor: unset;
  }
}



















.step-btn {
  display: grid;
  place-items: center;
  
  margin: 24px;
  width: 56px;
  height: 56px;
  
  border-radius: 50%;
  background-color: #282828;

  .sbi {
    font-size: 14px;
    font-weight: 700;
    color: $primary-color;
  }
}

.radio_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;

  width: 100%;
  padding: 16px 26px;

  @media screen and (max-width: 1000px) {
    padding: 16px 0;
  }
}

.radio_field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  
  width: 100%;
  padding: 10px 16px;

  border-radius: 8px;
  border: 1px solid #f2f4f8;
  
  cursor: pointer;

  label {
    font-size: clamp(15px,1.5vw, 17px);
    color: #e5e5e5;
  }

  .p-radiobutton-box {
    background: transparent !important;
  }
}



